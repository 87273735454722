import { useSelector } from 'react-redux';
import Header from './shared/components/header/Header';
import Kanban from './kanban/Kanban';
import { selectErrorMessage } from './shared/store/SharedSelectors';
import './App.scss';
import Toast from './shared/components/toast/Toast';

const App = () => {
  const errorMessage = useSelector(selectErrorMessage);

  return <div className="app">
    <Header></Header>
    <main>
      <Kanban></Kanban>
    </main>
    <Toast text={errorMessage}></Toast>
  </div>
}

export default App;
