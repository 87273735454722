import Search from '../search/Search';
import './Header.scss';

const Header = () => <header>
  <div className='header-content'>
    <div className='layout'>
      <h2>Kanban Board</h2>
      <Search></Search>
    </div>
  </div>
</header>

export default Header;
