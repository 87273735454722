import List from "../list/model/List";
import Ticket from "../ticket/model/Ticket";
import { MoveTicketData } from "../list/store/ListActions";

export const mapListsFromDTO = (lists: List[]) => lists.map((list: List) => ({
  ...list,
  tickets: list.tickets.map((ticket: Ticket) => ({ ...ticket }))
}));

export const findItemInArray = <T extends { id: string }>(array: T[], itemId: string) => {
  const foundArray = array.filter((item: T) => item.id === itemId);
  if (foundArray.length > 0) {
    return foundArray[0];
  }
  return undefined;
};

export const replaceItemInArray = <T extends { id: string }>(array: T[], newItem: T) => {
  const oldItem = findItemInArray(array, newItem.id);
  if (oldItem) {
    array.splice(array.indexOf(oldItem), 1, newItem);
  }
  return array;
};

export const moveTicketInLists = (lists: List[], data: MoveTicketData) => {
  if (data.source.listId === data.destination.listId) {
    const list = findItemInArray(lists, data.source.listId);
    if (list) {
      const movedTicket = list.tickets[data.source.index];
      if (data.destination.index > data.source.index) {
        list.tickets.splice(data.destination.index, 0, movedTicket);
        list.tickets.splice(data.source.index, 1);
      } else if (data.source.index > data.destination.index) {
        list.tickets.splice(data.source.index, 1);
        list.tickets.splice(data.destination.index, 0, movedTicket);
      }
    }
  } else {
    const sourceList = findItemInArray(lists, data.source.listId);
    const destinationList = findItemInArray(lists, data.destination.listId);
    if (sourceList && destinationList) {
      const movedTicket = sourceList.tickets[data.source.index];
      sourceList.tickets.splice(data.source.index, 1);
      destinationList.tickets.splice(data.destination.index, 0, movedTicket);
    }
  }
  return lists;
}
