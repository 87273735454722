import { useEffect } from 'react';
import { UnknownAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, DragStart, DropResult } from '@hello-pangea/dnd';
import List from '../list/model/List';
import { selectLists } from '../list/store/ListSelectors';
import { getLists, moveTicket } from '../list/store/ListActions';
import { endMove, startMove } from '../ticket/store/TicketActions';
import { selectTicketFilter } from '../ticket/store/TicketSelectors';
import ListComponent from '../list/components/list-component/ListComponent';
import './Kanban.scss';

const Kanban = () => {
  const dispatch = useDispatch();
  const lists = useSelector(selectLists);
  const filter = useSelector(selectTicketFilter);

  useEffect(() => {
    if (lists.length === 0) {
      dispatch(getLists() as unknown as UnknownAction);
    }
  }, [lists, dispatch])

  return <section className='kanban'>
    <DragDropContext onDragStart={(data: DragStart) => {
      dispatch(startMove(data.draggableId));
    }} onDragEnd={(data: DropResult) => {
      if(data.destination) {
        dispatch(moveTicket({
          ticketId: data.draggableId,
          source: {
            listId: data.source.droppableId,
            index: data.source.index,
          },
          destination: {
            listId: data.destination.droppableId,
            index: data.destination.index,
          }
        }) as unknown as UnknownAction);
        dispatch(endMove());
      }
    }}>
      { lists.map((list: List) => (<ListComponent key={list.id} list={list} filter={filter}></ListComponent>)) }
    </DragDropContext>
  </section>;
}

export default Kanban;
