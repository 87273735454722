import { SharedActions } from "./SharedActions";

export type SharedState = {
  errorMessage?: string,
};

const initialState: SharedState = {
  errorMessage: undefined,
};

type SharedActionType<T> = {
  type: SharedActions;
  payload: T;
}

const sharedReducer = (state: SharedState = initialState, action: SharedActionType<string>) => {
  switch (action.type) {
    case SharedActions.SetErrorMessage:
      return {
        ...state,
        errorMessage: action.payload,
      }
    case SharedActions.RemoveErrorMessage:
      return {
        ...state,
        errorMessage: undefined,
      };
    default:
      return state;
  }
};

export default sharedReducer;
