import { Droppable } from '@hello-pangea/dnd';
import { useDispatch, useSelector } from 'react-redux';
import List from '../../model/List';
import Ticket from '../../../ticket/model/Ticket';
import { createTicketInList } from '../../store/ListActions';
import { selectMovedTicketId } from '../../../ticket/store/TicketSelectors';
import TicketComponent from '../../../ticket/components/ticket-component/TicketComponent';
import './ListComponent.scss';
import { UnknownAction } from '@reduxjs/toolkit';

const DEFAULT_THEME = 'gray';
const SELECTED_THEME = 'selected';
const THEMES: { [key: string]: string } = {
  'To Do': 'blue',
  'In Progress': 'red',
  'Done': 'gray',
};

const ListComponent = ({
  list,
  filter,
}: {
  list: List,
  filter: string,
}) => {
  const dispatch = useDispatch();
  const movedTicketId = useSelector(selectMovedTicketId);

  return <section className={'list ' + (THEMES[list.name] || DEFAULT_THEME)}>
    <div className='list-header'>
      <span className='circle count'>{list.tickets.length}</span>
      <h5>{list.name}</h5>
      <div onClick={() => dispatch(createTicketInList(list.id) as unknown as UnknownAction)} className='circle add'>+</div>
    </div>
    <div className='list-content hide-scrollbar'>
      <Droppable droppableId={list.id}>
        {(provided) => (<div
          className='content-droppable'
          ref={provided.innerRef}
          {...provided.droppableProps}>
          {list.tickets.map((ticket: Ticket, index: number) => (
            <TicketComponent
              key={ticket.id}
              index={index}
              listId={list.id}
              ticket={ticket}
              filter={filter}
              theme={movedTicketId === ticket.id ? SELECTED_THEME : (THEMES[list.name] || DEFAULT_THEME)}
            ></TicketComponent>
          ))}
          {provided.placeholder}
        </div>)}
      </Droppable>
    </div>
  </section>;
}

export default ListComponent;
