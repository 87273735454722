import { Dispatch, UnknownAction } from "redux";
import { gql } from "@apollo/client";
import List from "../model/List";
import { selectLists } from "./ListSelectors";
import { State } from "../../shared/store/Store";
import apolloClient from "../../shared/ApolloClient";
import { setErrorMessage } from "../../shared/store/SharedActions";
import { mapListsFromDTO, moveTicketInLists } from "../../shared/Util";

export enum ListActions {
  SaveLists = '[List] SAVE LISTS',
  MoveTicketSync = '[List] MOVE TICKET SYNC',
}

export type MoveTicketData = {
  ticketId: string;
  source: {
    listId: string;
    index: number;
  },
  destination: {
    listId: string;
    index: number;
  }
}

const GET_LISTS = gql`
  query {
    getLists {
      id
      name
      tickets {
        id
        text
      }
    }
  }
`;

export const saveLists = (lists: List[]) => ({
  type: ListActions.SaveLists,
  payload: lists,
});

export const getLists = () =>
  async (dispatch: Dispatch) => {
    try {
      const { data } = await apolloClient.query({ query: GET_LISTS, fetchPolicy: 'no-cache' });
      dispatch(saveLists(mapListsFromDTO(data.getLists)));
    } catch (error) {
      dispatch(setErrorMessage((error as Error).message));
    }
  };

const CREATE_TICKET_IN_LIST = gql`
  mutation CreateTicketInList($listId: String!, $text: String!) {
    createTicketInList(listId: $listId, text: $text) {
      id
      text
    }
  }
`;

export const createTicketInList = (listId: string) =>
  async (dispatch: Dispatch) => {
    try {
      await apolloClient.mutate({
        mutation: CREATE_TICKET_IN_LIST,
        variables: {
          listId,
          text: '',
        },
      });
      dispatch(getLists() as unknown as UnknownAction);
    } catch (error) {
      dispatch(setErrorMessage((error as Error).message));
    }
  };

const REMOVE_TICKET = gql`
  mutation RemoveTicketFromList($listId: String!, $ticketId: String!) {
    removeTicketFromList(listId: $listId, ticketId: $ticketId)
  }
`;

export const removeTicketFromList = (listId: string, ticketId: string) =>
  async (dispatch: Dispatch) => {
    try {
      await apolloClient.mutate({
        mutation: REMOVE_TICKET,
        variables: {
          listId,
          ticketId,
        },
      });
      dispatch(getLists() as unknown as UnknownAction);
    } catch (error) {
      dispatch(setErrorMessage((error as Error).message));
    }
  };

const MOVE_TICKET = gql`
  mutation MoveTicket($moveTicketDTO: MoveTicketDTO!) {
    moveTicket(moveTicketDTO: $moveTicketDTO)
  }
`;

export const moveTicket = (moveTicketData: MoveTicketData) =>
  async (dispatch: Dispatch) => {
    dispatch(moveTicketSync(moveTicketData) as unknown as UnknownAction);
    try {
      await apolloClient.mutate({
        mutation: MOVE_TICKET,
        variables: {
          moveTicketDTO: moveTicketData
        },
      });
      dispatch(getLists() as unknown as UnknownAction);
    } catch (error) {
      dispatch(setErrorMessage((error as Error).message));
    }
  };

export const moveTicketSync = (moveTicketData: MoveTicketData) =>
  async (dispatch: Dispatch, getState: () => State) => {
    const lists = selectLists(getState());
    dispatch(saveLists(moveTicketInLists(mapListsFromDTO(lists), moveTicketData)));
  };
