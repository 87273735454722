export enum SharedActions {
  SetErrorMessage = '[Shared] SET ERROR MESSAGE',
  RemoveErrorMessage = '[Shared] REMOVE ERROR MESSAGE',
}

export const setErrorMessage = (errorMessage: string) => ({
  type: SharedActions.SetErrorMessage,
  payload: errorMessage,
});

export const removeErrorMessage = () => ({
  type: SharedActions.RemoveErrorMessage,
});
