import { TicketActions } from "./TicketActions";

export type TicketState = {
  ticketFilter: string;
  movedTicketId?: string;
};

const initialState: TicketState = {
  ticketFilter: '',
};

type TicketActionType<T> = {
  type: string;
  payload: T;
}

const ticketReducer = (state: TicketState = initialState, action: TicketActionType<string>) => {
  switch (action.type) {
    case TicketActions.StartMove:
      return {
        ...state,
        movedTicketId: action.payload,
      };
    case TicketActions.EndMove:
      return {
        ...state,
        movedTicketId: undefined,
      };
    case TicketActions.SetFilter:
      return {
        ...state,
        ticketFilter: action.payload,
      };
    default:
      return state;
  }
};

export default ticketReducer;
