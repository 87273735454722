import { useDispatch } from 'react-redux';
import { Draggable } from '@hello-pangea/dnd';
import { UnknownAction } from '@reduxjs/toolkit';
import { useMemo, useRef, useState } from 'react';
import Ticket from '../../model/Ticket';
import { updateTicket } from '../../store/TicketActions';
import { removeTicketFromList } from '../../../list/store/ListActions';
import './TicketComponent.scss';

const DEFAULT_THEME = 'blue';

const TicketComponent = ({
  index,
  listId,
  filter,
  ticket,
  theme = DEFAULT_THEME,
}: {
  index: number,
  listId: string,
  filter: string,
  ticket: Ticket,
  theme?: string,
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [editMode, setEditMode] = useState(false);
  const [editedText, setEditedText] = useState<string | undefined>(undefined);
  const displayText = useMemo(() => editedText || ticket.text, [editedText, ticket]);
  const obscured = useMemo(() => !ticket.text.toLowerCase().includes(filter.toLowerCase()) ? ' obscured' : '', [ticket, filter]);

  const onDoubleClick = () => {
    setEditMode(true);
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.value = displayText;
        (inputRef.current as HTMLTextAreaElement).focus()
      }
    }, 1);
  };

  const onInputBlur = () => {
    if(inputRef?.current && ticket.text !== inputRef.current.value) {
      setEditedText(inputRef.current?.value);
      dispatch(updateTicket(ticket.id, inputRef.current?.value) as unknown as UnknownAction);
    }
    setEditMode(false);
  }

  const onDeleteClick = () => {
    dispatch(removeTicketFromList(listId, ticket.id) as unknown as UnknownAction);
  }

  return <Draggable draggableId={ticket.id} index={index} key={ticket.id}>
    {(provided) => (<section
      className={'ticket ' + theme + obscured}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onDoubleClick={onDoubleClick}>
      {!editMode && displayText && <div className='ticket-content no-select'>
        {displayText}
      </div>}
      {!editMode && !displayText && <div className='ticket-placeholder no-select'>
        Double click to type something
      </div>}
      {!editMode && <div className='delete' onClick={onDeleteClick}>X</div>}
      {editMode && <textarea ref={inputRef} onBlur={onInputBlur}></textarea>}
    </section>)}
  </Draggable>
}

export default TicketComponent;
