import { useDispatch } from 'react-redux';
import { removeErrorMessage } from '../../store/SharedActions';
import './Toast.scss';

const Toast = ({
  text,
}: {
  text?: string,
}) => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(removeErrorMessage())

  return <div className={'toast ' + ((text) ? 'visible' : 'hidden')} onClick={onClick}>
    <span>{text}</span>
    <span className='dismiss-message'>Click to dismiss</span>
  </div>
}

export default Toast;
