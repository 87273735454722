import List from "../model/List";
import { ListActions, MoveTicketData } from "./ListActions";

export type ListState = {
  lists: List[];
};

const initialState: ListState = {
  lists: [],
};

type ListActionType<T> = {
  type: ListActions;
  payload: T;
}

const listReducer = (state: ListState = initialState, action: ListActionType<List[] | MoveTicketData>) => {
  switch (action.type) {
    case ListActions.SaveLists:
      return {
        ...state,
        lists: [...(action.payload as List[])],
      }
    default:
      return state;
  }
};

export default listReducer;
