import { gql } from "@apollo/client";
import { Dispatch, UnknownAction } from "redux";
import apolloClient from "../../shared/ApolloClient";
import { getLists } from "../../list/store/ListActions";
import { setErrorMessage } from "../../shared/store/SharedActions";

export const ADD_TICKET = 'ADD_TICKET';

export enum TicketActions {
  StartMove = '[TICKET] START MOVE',
  EndMove = '[TICKET] END MOVE',
  SetFilter = '[TICKET] SET FILTER',
}

export const startMove = (ticketId: string) => ({
  type: TicketActions.StartMove,
  payload: ticketId,
});

export const endMove = () => ({
  type: TicketActions.EndMove,
});

const UPDATE_TICKET = gql`
  mutation UpdateTicket($id: String!, $text: String!) {
    updateTicket(id: $id, text: $text) {
      id
      text
    }
  }
`;

export const updateTicket = (id: string, text: string) =>
  async (dispatch: Dispatch) => {
    try {
      await apolloClient.mutate({
        mutation: UPDATE_TICKET,
        variables: {
          id,
          text,
        },
      });
      dispatch(getLists() as unknown as UnknownAction);
    } catch (error) {
      dispatch(setErrorMessage((error as Error).message));
    }
  };

export const setFilter = (filter: string) => ({
  type: TicketActions.SetFilter,
  payload: filter,
});
