import { SyntheticEvent } from 'react';
import './Search.scss';
import { useDispatch } from 'react-redux';
import { setFilter } from '../../../ticket/store/TicketActions';

const Search = () => {
  const dispatch = useDispatch();
  const onInput = (data: SyntheticEvent) => {
    dispatch(setFilter((data.target as HTMLInputElement).value))
  }

  return <input
    type='text'
    className='search'
    placeholder='Type in search term...'
    onInput={onInput}>
  </input>
}

export default Search;
