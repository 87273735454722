import { combineReducers } from 'redux';
import listReducer, { ListState } from '../../list/store/ListReducer';
import ticketReducer, { TicketState } from '../../ticket/store/TicketReducer';
import sharedReducer, { SharedState } from './SharedReducer';
import { configureStore } from '@reduxjs/toolkit';

export type State = {
  list: ListState,
  ticket: TicketState,
  shared: SharedState,
}

const rootReducer = combineReducers({
  list: listReducer,
  ticket: ticketReducer,
  shared: sharedReducer,
});

const store = configureStore({
  reducer: rootReducer,
})

export default store;
